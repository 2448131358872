<template>
  <div class="stlye-table-frais-be">
    <b-table
      show-empty
      id="my-table"
      class="custom-table-style customTableFraisBureauEtude table-header"
      :class="{ customTableFraisBureauEtudeRezise: this.filterType == null }"
      :items="computedFraisBureauEtude"
      :fields="computedFields"
      bordered
      sticky-header
      hover
      responsive
      head-variant="light"
      :tbody-tr-class="rowClass"
      empty-text="Il n'y a aucun enregistrement à afficher"
      :sort-by.sync="sortBy"
    >
      <template v-slot:head()="data">
        <span
          v-if="data.field.key != 'check_all'"
          :class="{
            'white-color': computedHeaderVisibility(data.field.key)
          }"
          class="text-uppercase"
        >
          {{ data.field.label }}</span
        >
        <div v-else class="text-uppercase">
          <b-form-checkbox
            v-model="checkAll"
            @change="checkAllFunction"
            class="check-th"
            :value="true"
            :unchecked-value="false"
          >
          </b-form-checkbox>
        </div>
      </template>
      <template v-slot:cell(check_all)="data">
        <b-form-checkbox
          v-model="data.item.check"
          @change="checkAllVerif(data.item)"
          class="check-th"
          :value="true"
          :unchecked-value="false"
          v-if="data.item.projects.length != 0"
        >
        </b-form-checkbox>
      </template>
      <template v-slot:cell(details)="data">
        <button class="btn btn-anomalie" @click="getDataForRow(data)">
          <font-awesome-icon
            class="icon-details"
            icon="plus-square"
            v-if="data.detailsShowing == false"
            title="Afficher les dossiers"
          />
          <font-awesome-icon
            class="icon-details"
            icon="minus-square"
            v-if="data.detailsShowing == true"
            title="Masques les dossiers"
          />
        </button>
      </template>
      <template v-slot:cell(nom)="data">
        {{ data.item.nom }}
      </template>
      <template v-slot:cell(installeur)="data">
        <span
          @click="handleDownloadExtraitInstalleur(data.item.name)"
          :title="'Télécharger extrait ' + data.item.name"
        >
          {{ data.item.name }}
        </span></template
      >
      <template v-slot:cell(bureau_etude)="data"
        ><span
          @click="
            handleDownloadExtraitSupportService({
              item: data.item,
              type: 'bureau_detude'
            })
          "
          class="span-extrait"
          >{{ data.item.be }}</span
        ></template
      >
      <template v-slot:cell(binifis_alteo)="data"
        ><span
          @click="
            handleDownloadExtraitSupportService({
              item: data.item,
              type: 'binifis_alteo'
            })
          "
          class="span-extrait"
          >{{ data.item.alteo }}</span
        ></template
      >
      <template v-slot:cell(binifis_kynan)="data"
        ><span
          @click="
            handleDownloadExtraitSupportService({
              item: data.item,
              type: 'binifis_kynan'
            })
          "
          class="span-extrait"
          >{{ data.item.kynan }}</span
        ></template
      >
      <template v-slot:cell(prenom)="data">{{ data.item.prenom }}</template>
      <template v-slot:cell(numero_dossier)="">-</template>
      <template v-slot:cell(bureau_etude_type)=""> - </template>
      <template v-slot:cell(statue_bureau_etude)=""> - </template>
      <template v-slot:cell(statue_kynan)=""> - </template>
      <template v-slot:cell(statue_alteo)=""> - </template>
      <template v-slot:cell(date_kynan)=""> - </template>
      <template v-slot:cell(date_alteo)=""> - </template>
      <template v-slot:cell(date_bureau_etude)=""> - </template>
      <template v-slot:cell(total)="data"> {{ data.item.total }} </template>
      <template v-slot:cell(facture_bureau_etude_numero)=""> - </template>
      <template v-slot:cell(facture_kynan_numero)=""> - </template>
      <template v-slot:cell(facture_alteo_numero)=""> - </template>

      <template #row-details="data">
        <div v-if="data.item.loadingDetailt">
          <circles-to-rhombuses-spinner
            :animation-duration="1500"
            :size="50"
            :color="'#ff1d5e'"
            class="loading-details-frais"
          />
        </div>
        <span
          v-else
          v-for="(item, index) in data.item.projects"
          :key="data.item.nom + item.numero_dossier + index"
          class="align-td-item border-botom"
        >
          <td class="color">
            <div class="block-service-style">
              <b-form-checkbox
                v-model="item.check"
                @change="checkAllVerifDetails(data.item, item)"
                class="check-th-details ml-2"
                :value="true"
                :unchecked-value="false"
              >
              </b-form-checkbox>
            </div>
          </td>
          <td class="details-column-frais">
            <div class="block-service-style"></div>
          </td>
          <td class="installeur-column-frais">
            <div class="block-service-style">{{ item.nom_prenom }}</div>
          </td>
          <td class="item-details-style">
            {{ item.numero_dossier }}
          </td>
          <td
            class=" item-details-style justify-content-sb-bureau-etude"
            v-if="
              computedShowDetailsColomn({
                value: 'KYNAN - ISO 33 - ISAUDIT',
                key: 'bureau_etude_type'
              })
            "
          >
            <EditableInput
              :editable="false"
              champName="BE_TYPE"
              editableType="select"
              :optionsSelect="computedBureauDetudeList"
              :item="item"
              :value="item.BE_TYPE"
              :updateFunction="updateBeType"
              label="value"
              :positionSelect="true"
              formate="regieFacture"
              :showIconEdit="true"
              :allowEmpty="false"
            />
          </td>
          <template v-if="!computedFactureVide(item.montant, item.statue)">
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'KYNAN - ISO 33 - ISAUDIT',
                  key: 'montant'
                })
              "
            >
              <div class="block-service-style">
                <span
                  class="default-color-statue"
                  :class="{
                    'red-color': item.statue == 'facture non validée',
                    'green-color': item.statue == 'paye',
                    'red-color montant-bare': item.statue == 'annulee'
                  }"
                >
                  {{ item.montant }} €
                </span>
                <font-awesome-icon
                  v-if="
                    (cantExport(name) &&
                      item.downloadable == true &&
                      !isUserIso33 &&
                      !isUserIsodit &&
                      !isUserEffimax &&
                      !isUserCWFCONSULTING) ||
                      (cantExport(name) &&
                        item.downloadable == true &&
                        ((isUserIso33 && item.BE_TYPE == 'ISO 33') ||
                          (isUserIsodit && item.BE_TYPE == 'ISODIT') ||
                          (isUserEffimax && item.BE_TYPE == 'EFFIMAX') ||
                          (isUserCWFCONSULTING &&
                            item.BE_TYPE == 'CWF CONSULTING')))
                  "
                  icon="arrow-alt-circle-down"
                  @click.prevent="
                    downloadFacture({
                      data: item,
                      type: 'KYNAN - ISO 33 - ISAUDIT'
                    })
                  "
                  class="icon-style-color mr-1"
                  title="Télécharger la facture"
                />
                <font-awesome-icon
                  v-if="
                    (cantExport(name) &&
                      item.facture_av_id != null &&
                      !isUserIso33 &&
                      !isUserIsodit &&
                      !isUserEffimax &&
                      !isUserCWFCONSULTING) ||
                      (cantExport(name) &&
                        item.facture_av_id != null &&
                        ((isUserIso33 && item.BE_TYPE == 'ISO 33') ||
                          (isUserIsodit && item.BE_TYPE == 'ISODIT') ||
                          (isUserEffimax && item.BE_TYPE == 'EFFIMAX') ||
                          (isUserCWFCONSULTING &&
                            item.BE_TYPE == 'CWF CONSULTING')))
                  "
                  icon="arrow-alt-circle-down"
                  @click.prevent="
                    downloadFactureAvoir({
                      data: item,
                      type: 'KYNAN - ISO 33 - ISAUDIT'
                    })
                  "
                  class="icon-style-color mr-1 button-export-style-avoir "
                  title="Télécharger la facture avoir"
                />
                <div
                  :class="
                    item.email_sent_at == null
                      ? 'envelop-icon-container mr-1'
                      : 'background-email-sent mr-1'
                  "
                  v-if="
                    item.downloadable == true &&
                      !isSuperAdmin &&
                      cantSend(name) &&
                      !isResponsableAlteao
                  "
                >
                  <font-awesome-icon
                    icon="envelope"
                    class=" 'icon-style-color envelop-icon mr-1"
                  />
                </div>

                <template-bureau-etude
                  v-if="
                    (cantCreate(name) &&
                      !isSuperAdmin &&
                      item.statue != 'paye' &&
                      item.statue != 'annulee' &&
                      !isResponsableAlteao &&
                      !isUserIso33 &&
                      !isUserIsodit &&
                      !isUserEffimax &&
                      !isUserCWFCONSULTING) ||
                      (cantExport(name) &&
                        !isSuperAdmin &&
                        item.statue != 'paye' &&
                        item.statue != 'annulee' &&
                        !isResponsableAlteao &&
                        ((isUserIso33 && item.BE_TYPE == 'ISO 33') ||
                          (isUserIsodit && item.BE_TYPE == 'ISODIT') ||
                          (isUserEffimax && item.BE_TYPE == 'EFFIMAX') ||
                          (isUserCWFCONSULTING &&
                            item.BE_TYPE == 'CWF CONSULTING')))
                  "
                  :month="filterMonth"
                  :year="filteryear"
                  :item="item"
                  type="KYNAN - ISO 33 - ISAUDIT"
                  :updateFunction="
                    uploadBureauEtudeTemplateInServieVisionFiliale
                  "
                  :getTemplateFunction="
                    getBureauEtudeTemplateAndFiledsDataToUpdate
                  "
                />
                <font-awesome-icon
                  v-if="
                    (cantCreate(name) &&
                      !isSuperAdmin &&
                      item.downloadable == true &&
                      item.statue != 'paye' &&
                      item.statue != 'annulee' &&
                      !isResponsableAlteao &&
                      !isUserIso33 &&
                      !isUserIsodit &&
                      !isUserEffimax &&
                      !isUserCWFCONSULTING) ||
                      (cantExport(name) &&
                        !isSuperAdmin &&
                        !isResponsableAlteao &&
                        item.downloadable == true &&
                        item.statue != 'paye' &&
                        item.statue != 'annulee' &&
                        ((isUserIso33 && item.BE_TYPE == 'ISO 33') ||
                          (isUserIsodit && item.BE_TYPE == 'ISODIT') ||
                          (isUserEffimax && item.BE_TYPE == 'EFFIMAX') ||
                          (isUserCWFCONSULTING &&
                            item.BE_TYPE == 'CWF CONSULTING')))
                  "
                  icon="check-circle"
                  class="icon-style-check-facture"
                  title="facture validée"
                />
              </div>
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'KYNAN - ISO 33 - ISAUDIT',
                  key: 'statue'
                })
              "
            >
              <paiement-column-component
                :editable="
                  (cantCreate(name) &&
                    !isSuperAdmin &&
                    item.downloadable == true &&
                    item.statue != 'paye' &&
                    item.statue != 'annulee' &&
                    !isResponsableAlteao &&
                    !isUserIso33 &&
                    !isUserIsodit &&
                    !isUserEffimax &&
                    !isUserCWFCONSULTING) ||
                    (cantExport(name) &&
                      !isSuperAdmin &&
                      !isResponsableAlteao &&
                      item.downloadable == true &&
                      item.statue != 'paye' &&
                      item.statue != 'annulee' &&
                      ((isUserIso33 && item.BE_TYPE == 'ISO 33') ||
                        (isUserIsodit && item.BE_TYPE == 'ISODIT') ||
                        (isUserEffimax && item.BE_TYPE == 'EFFIMAX') ||
                        (isUserCWFCONSULTING &&
                          item.BE_TYPE == 'CWF CONSULTING')))
                "
                champName="facture"
                :item="item"
                :value="item.type_paiement"
                :updateFunction="updattypePaiementFrais"
              />
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'KYNAN - ISO 33 - ISAUDIT',
                  key: 'statue'
                })
              "
            >
              <div>
                <EditableInput
                  :editable="
                    (isSuperAdmin ||
                      (cantUpdate(name) &&
                        !isSuperAdmin &&
                        item.statue != 'paye' &&
                        item.statue != 'annulee' &&
                        item.downloadable == true &&
                        !isResponsableFiliale &&
                        !isResponsableAlteao &&
                        ((isUserIso33 && item.BE_TYPE == 'ISO 33') ||
                          (isUserIsodit && item.BE_TYPE == 'ISODIT') ||
                          (isUserEffimax && item.BE_TYPE == 'EFFIMAX') ||
                          (isUserCWFCONSULTING &&
                            item.BE_TYPE == 'CWF CONSULTING'))) ||
                      isUserBe) &&
                      item.statue != 'facture non validée'
                  "
                  champName="statue"
                  editableType="select"
                  :optionsSelect="computedValidationList"
                  :item="item"
                  :value="item.statue"
                  :updateFunction="updateStatueFraisBureauEtude"
                  label="value"
                  :positionSelect="true"
                  formate="regieFacture"
                  :showIconEdit="true"
                />
              </div>
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'KYNAN - ISO 33 - ISAUDIT',
                  key: 'date'
                })
              "
            >
              {{ item.date }}
            </td>

            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'KYNAN - ISO 33 - ISAUDIT',
                  key: 'facture_numero'
                })
              "
            >
              {{ item.facture_numero }}
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'KYNAN - ISO 33 - ISAUDIT',
                  key: 'statue_installateur'
                })
              "
            >
              <div class="block-service-style">
                <EditableInput
                  :editable="
                    computedEditableStatutInstallateurFrais({
                      item: item,
                      column: null
                    })
                  "
                  champName="statue_installateur"
                  editableType="select"
                  :optionsSelect="computedValidationListInstallateur"
                  :item="item"
                  :value="item.statue_installateur"
                  :updateFunction="updateStatueInstallateurFraisBureauEtude"
                  label="value"
                  :positionSelect="true"
                  formate="regieFacture"
                  :showIconEdit="true"
                />
                <icon-info-component :item="item.responsable_email" />
              </div>
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'KYNAN - ISO 33 - ISAUDIT',
                  key: 'email_sent_at'
                })
              "
            >
              {{ item.email_sent_at }}
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'KYNAN - ISO 33 - ISAUDIT',
                  key: 'lot'
                })
              "
            >
              {{ item.lot }}
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'KYNAN - ISO 33 - ISAUDIT',
                  key: 'lot'
                })
              "
            >
              {{ item.facture_av_numero }}
            </td>
          </template>

          <template v-else>
            <td
              class=" item-details-style justify-content-sb-bureau-etude text-center"
              v-if="
                computedShowDetailsColomn({
                  value: 'KYNAN - ISO 33 - ISAUDIT',
                  key: 'montant'
                })
              "
            >
              -
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'KYNAN - ISO 33 - ISAUDIT',
                  key: 'statue'
                })
              "
            >
              -
            </td>

            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'KYNAN - ISO 33 - ISAUDIT',
                  key: 'statue'
                })
              "
            >
              -
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'KYNAN - ISO 33 - ISAUDIT',
                  key: 'date'
                })
              "
            >
              -
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'KYNAN - ISO 33 - ISAUDIT',
                  key: 'facture_numero'
                })
              "
            >
              -
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'KYNAN - ISO 33 - ISAUDIT',
                  key: 'statue_installateur'
                })
              "
            >
              -
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'KYNAN - ISO 33 - ISAUDIT',
                  key: 'email_sent_at'
                })
              "
            >
              -
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'KYNAN - ISO 33 - ISAUDIT',
                  key: 'lot'
                })
              "
            >
              -
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'KYNAN - ISO 33 - ISAUDIT',
                  key: 'lot'
                })
              "
            >
              -
            </td>
          </template>
          <template
            v-if="!computedFactureVide(item.binifis_kynan, item.statue_kynan)"
          >
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'BE B KYNAN',
                  key: 'binifis_kynan'
                })
              "
            >
              <div class="block-service-style">
                <span
                  class="default-color-statue"
                  :class="{
                    'red-color': item.statue_kynan == 'facture non validée',
                    'green-color': item.statue_kynan == 'paye',
                    'red-color montant-bare': item.statue_kynan == 'annulee'
                  }"
                >
                  <EditableInput
                    :editable="
                      cantUpdate(name) &&
                        !isSuperAdmin &&
                        item.BE_TYPE != 'KYNAN' &&
                        item.statue_kynan != 'paye' &&
                        item.statue_kynan != 'annulee' &&
                        !isResponsableFiliale
                    "
                    champName="binifis_kynan"
                    type="text"
                    :item="item"
                    :value="item.binifis_kynan"
                    :updateFunction="updateMontantFraisBureauEtudeB"
                    label="value"
                    :positionSelect="true"
                    :defaultColor="true"
                    :showIconEdit="true"
                    uniteMontant=" €"
                  />
                </span>

                <font-awesome-icon
                  v-if="
                    cantExport(name) &&
                      item.downloadable_kynan == true &&
                      item.BE_TYPE != 'KYNAN'
                  "
                  icon="arrow-alt-circle-down"
                  @click.prevent="
                    downloadFacture({
                      data: item,
                      type: 'BE B KYNAN',
                      facture: 'facture_kynan_id'
                    })
                  "
                  title="Télécharger la facture"
                  class="icon-style-color mr-1 ml-2"
                />
                <font-awesome-icon
                  v-if="
                    cantExport(name) &&
                      item.facture_kynan_av_id != null &&
                      item.BE_TYPE != 'KYNAN'
                  "
                  icon="arrow-alt-circle-down"
                  @click.prevent="
                    downloadFactureAvoir({
                      data: item,
                      type: 'BE B KYNAN',
                      facture: 'facture_kynan_av_id'
                    })
                  "
                  title="Télécharger la facture ac=voir"
                  class="icon-style-color mr-1 ml-2 button-export-style-avoir "
                />
                <div
                  :class="
                    item.kynan_email_sent_at == null
                      ? 'envelop-icon-container mr-1'
                      : 'background-email-sent mr-1'
                  "
                  v-if="
                    item.download == true &&
                      !isSuperAdmin &&
                      cantSend(componentName)
                  "
                >
                  <font-awesome-icon
                    icon="envelope"
                    class=" 'icon-style-color envelop-icon mr-1"
                  />
                </div>
                <template-bureau-etude
                  v-if="
                    cantCreate(name) &&
                      !isSuperAdmin &&
                      item.BE_TYPE != 'KYNAN' &&
                      item.statue_kynan != 'paye' &&
                      item.statue_kynan != 'annulee'
                  "
                  :month="filterMonth"
                  :year="filteryear"
                  :item="item"
                  type="BE B KYNAN"
                  :updateFunction="
                    uploadBureauEtudeTemplateInServieVisionFiliale
                  "
                  :getTemplateFunction="
                    getBureauEtudeTemplateAndFiledsDataToUpdate
                  "
                />
                <font-awesome-icon
                  v-if="
                    cantCreate(name) &&
                      !isSuperAdmin &&
                      item.downloadable_kynan == true &&
                      item.BE_TYPE != 'KYNAN' &&
                      item.statue_kynan != 'paye' &&
                      item.statue_kynan != 'annulee'
                  "
                  icon="check-circle"
                  class="icon-style-check-facture"
                  title="facture validée"
                />
              </div>
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'BE B KYNAN',
                  key: 'statue_kynan'
                })
              "
            >
              <div>
                <paiement-column-component
                  :editable="
                    cantCreate(name) &&
                      !isSuperAdmin &&
                      item.downloadable_kynan == true &&
                      item.BE_TYPE != 'KYNAN' &&
                      item.statue_kynan != 'paye' &&
                      item.statue_kynan != 'annulee'
                  "
                  champName="kynan"
                  :item="item"
                  :value="item.kynan_type_paiement"
                  :updateFunction="updattypePaiementFrais"
                />
              </div>
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'BE B KYNAN',
                  key: 'statue_kynan'
                })
              "
            >
              <div>
                <EditableInput
                  v-if="item.downloadable_kynan == true"
                  :editable="
                    (isSuperAdmin ||
                      (cantUpdate(name) &&
                        !isSuperAdmin &&
                        item.statue_kynan != 'paye' &&
                        item.statue_kynan != 'annulee' &&
                        !isResponsableFiliale)) &&
                      item.statue_kynan != 'facture non validée'
                  "
                  champName="statue_kynan"
                  editableType="select"
                  :optionsSelect="computedValidationList"
                  :item="item"
                  :value="item.statue_kynan"
                  :updateFunction="updateStatueFraisBureauEtude"
                  label="value"
                  :positionSelect="true"
                  formate="regieFacture"
                  :showIconEdit="true"
                />
              </div>
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'BE B KYNAN',
                  key: 'date_kynan'
                })
              "
            >
              {{ item.date_kynan }}
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'BE B KYNAN',
                  key: 'facture_kynan_numero'
                })
              "
            >
              {{ item.facture_kynan_numero }}
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'BE B KYNAN',
                  key: 'kynan_statue_installateur'
                })
              "
            >
              <div class="block-service-style">
                <EditableInput
                  v-if="item.downloadable_kynan == true"
                  :editable="
                    computedEditableStatutInstallateurFrais({
                      item: item,
                      column: 'kynan'
                    })
                  "
                  champName="kynan_statue_installateur"
                  editableType="select"
                  :optionsSelect="computedValidationListInstallateur"
                  :item="item"
                  :value="item.kynan_statue_installateur"
                  :updateFunction="updateStatueInstallateurFraisBureauEtude"
                  label="value"
                  :positionSelect="true"
                  formate="regieFacture"
                  :showIconEdit="true"
                />
                <icon-info-component :item="item.kynan_responsable_email" />
              </div>
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'BE B KYNAN',
                  key: 'kynan_email_sent_at'
                })
              "
            >
              {{ item.kynan_email_sent_at }}
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'BE B KYNAN',
                  key: 'kynan_lot'
                })
              "
            >
              {{ item.kynan_lot }}
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'BE B KYNAN',
                  key: 'kynan_lot'
                })
              "
            >
              {{ item.facture_kynan_av_numero }}
            </td>
          </template>
          <template v-else>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'BE B KYNAN',
                  key: 'binifis_kynan'
                })
              "
            >
              -
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'BE B KYNAN',
                  key: 'statue_kynan'
                })
              "
            >
              -
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'BE B KYNAN',
                  key: 'statue_kynan'
                })
              "
            >
              -
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'BE B KYNAN',
                  key: 'date_kynan'
                })
              "
            >
              -
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'BE B KYNAN',
                  key: 'facture_kynan_numero'
                })
              "
            >
              -
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'BE B KYNAN',
                  key: 'kynan_statue_installateur'
                })
              "
            >
              -
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'BE B KYNAN',
                  key: 'kynan_email_sent_at'
                })
              "
            >
              -
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'BE B KYNAN',
                  key: 'kynan_lot'
                })
              "
            >
              -
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'BE B KYNAN',
                  key: 'kynan_lot'
                })
              "
            >
              -
            </td>
          </template>
          <template
            v-if="!computedFactureVide(item.binifis_alteo, item.statue_alteo)"
          >
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'BE B ALTEO',
                  key: 'binifis_alteo'
                })
              "
            >
              <div class="block-service-style">
                <span
                  class="default-color-statue"
                  :class="{
                    'red-color': item.statue_alteo == 'facture non validée',
                    'green-color': item.statue_alteo == 'paye',
                    'red-color montant-bare': item.statue_alteo == 'annulee'
                  }"
                >
                  <EditableInput
                    :editable="
                      isSuperAdmin ||
                        (computedCantUpdate &&
                          item.statue_alteo != 'paye' &&
                          item.statue_alteo != 'annulee' &&
                          !isResponsableFiliale)
                    "
                    champName="binifis_alteo"
                    type="text"
                    :item="item"
                    :value="item.binifis_alteo"
                    :updateFunction="updateMontantFraisBureauEtudeB"
                    label="value"
                    :positionSelect="true"
                    :defaultColor="true"
                    :showIconEdit="true"
                    uniteMontant=" €"
                  />
                </span>

                <font-awesome-icon
                  v-if="cantExport(name) && item.downloadable_alteo == true"
                  icon="arrow-alt-circle-down"
                  @click.prevent="
                    downloadFacture({
                      data: item,
                      type: 'BE B ALTEO',
                      facture: 'facture_alteo_id'
                    })
                  "
                  title="Télécharger la facture"
                  class="icon-style-color mr-1 ml-2"
                />
                <font-awesome-icon
                  v-if="cantExport(name) && item.facture_alteo_av_id != null"
                  icon="arrow-alt-circle-down"
                  @click.prevent="
                    downloadFactureAvoir({
                      data: item,
                      type: 'BE B ALTEO',
                      facture: 'facture_alteo_av_id'
                    })
                  "
                  title="Télécharger la facture avoir"
                  class="icon-style-color mr-1 ml-2 button-export-style-avoir "
                />

                <div
                  :class="
                    item.alger_email_sent_at == null
                      ? 'envelop-icon-container mr-1'
                      : 'background-email-sent mr-1'
                  "
                  v-if="
                    item.download == true &&
                      !isSuperAdmin &&
                      cantSend(componentName)
                  "
                >
                  <font-awesome-icon
                    icon="envelope"
                    class=" 'icon-style-color envelop-icon mr-1"
                  />
                </div>
                <template-bureau-etude
                  v-if="
                    computedCantCreate &&
                      item.statue_alteo != 'paye' &&
                      item.statue_alteo != 'annulee'
                  "
                  :month="filterMonth"
                  :year="filteryear"
                  :item="item"
                  :updateFunction="
                    uploadBureauEtudeTemplateInServieVisionFiliale
                  "
                  :getTemplateFunction="
                    getBureauEtudeTemplateAndFiledsDataToUpdate
                  "
                  type="BE B ALTEO"
                />
                <font-awesome-icon
                  v-if="
                    computedCantCreate &&
                      item.downloadable_alteo == true &&
                      item.statue_alteo != 'paye' &&
                      item.statue_alteo != 'annulee'
                  "
                  icon="check-circle"
                  class="icon-style-check-facture"
                  title="facture validée"
                />
              </div>
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'BE B ALTEO',
                  key: 'statue_alteo'
                })
              "
            >
              <div>
                <paiement-column-component
                  :editable="
                    computedCantCreate &&
                      item.downloadable_alteo == true &&
                      item.statue_alteo != 'paye' &&
                      item.statue_alteo != 'annulee'
                  "
                  champName="facture_alteo"
                  :item="item"
                  :value="item.alteo_type_paiement"
                  :updateFunction="updattypePaiementFrais"
                />
              </div>
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'BE B ALTEO',
                  key: 'statue_alteo'
                })
              "
            >
              <div>
                <EditableInput
                  :editable="
                    (isSuperAdmin ||
                      (computedCantUpdate &&
                        item.statue_alteo != 'paye' &&
                        item.statue_alteo != 'annulee' &&
                        item.downloadable_alteo == true &&
                        !isResponsableFiliale)) &&
                      item.statue_alteo != 'facture non validée'
                  "
                  champName="statue_alteo"
                  editableType="select"
                  :optionsSelect="computedValidationList"
                  :item="item"
                  :value="item.statue_alteo"
                  :updateFunction="updateStatueFraisBureauEtude"
                  label="value"
                  :positionSelect="true"
                  formate="regieFacture"
                  :showIconEdit="true"
                />
              </div>
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'BE B ALTEO',
                  key: 'date_alteo'
                })
              "
            >
              {{ item.date_alteo }}
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'BE B ALTEO',
                  key: 'facture_alteo_numero'
                })
              "
            >
              {{ item.facture_alteo_numero }}
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'BE B ALTEO',
                  key: 'alteo_statue_installateur'
                })
              "
            >
              <div class="block-service-style">
                <EditableInput
                  :editable="
                    computedEditableStatutInstallateurFrais({
                      item: item,
                      column: 'alteo'
                    })
                  "
                  champName="alteo_statue_installateur"
                  editableType="select"
                  :optionsSelect="computedValidationListInstallateur"
                  :item="item"
                  :value="item.alteo_statue_installateur"
                  :updateFunction="updateStatueInstallateurFraisBureauEtude"
                  label="value"
                  :positionSelect="true"
                  formate="regieFacture"
                  :showIconEdit="true"
                />
                <icon-info-component :item="item.alteo_responsable_email" />
              </div>
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'BE B ALTEO',
                  key: 'alteo_email_sent_at'
                })
              "
            >
              {{ item.alteo_email_sent_at }}
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'BE B ALTEO',
                  key: 'alteo_lot'
                })
              "
            >
              {{ item.alteo_lot }}
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'BE B ALTEO',
                  key: 'alteo_lot'
                })
              "
            >
              {{ item.facture_alteo_av_numero }}
            </td>
          </template>
          <template v-else>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'BE B ALTEO',
                  key: 'binifis_alteo'
                })
              "
            >
              <div class="block-service-style">
                -
              </div>
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'BE B ALTEO',
                  key: 'statue_alteo'
                })
              "
            >
              -
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'BE B ALTEO',
                  key: 'statue_alteo'
                })
              "
            >
              -
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'BE B ALTEO',
                  key: 'date_alteo'
                })
              "
            >
              -
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'BE B ALTEO',
                  key: 'facture_alteo_numero'
                })
              "
            >
              -
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'BE B ALTEO',
                  key: 'alteo_statue_installateur'
                })
              "
            >
              -
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'BE B ALTEO',
                  key: 'alteo_email_sent_at'
                })
              "
            >
              -
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'BE B ALTEO',
                  key: 'alteo_lot'
                })
              "
            >
              -
            </td>
            <td
              class=" item-details-style justify-content-sb-bureau-etude"
              v-if="
                computedShowDetailsColomn({
                  value: 'BE B ALTEO',
                  key: 'alteo_lot'
                })
              "
            >
              -
            </td>
          </template>
        </span></template
      >
      <!-- <template
        #table-caption
        v-if="gettotalFraisBureauEtude != null && getFraisBureauEtude.length"
      >
        <b-tr class="tr-generale">
          <b-td class=" color bg-color-total-be"> </b-td>
          <b-td class=" details-column-frais bg-color-total-be"> </b-td>
          <b-td class="installeur-column-frais bg-color-total-be"> -</b-td>

          <b-td class=" bg-color-total-be">- </b-td>
          <b-td
            v-if="
              filterType == null || filterType == 'KYNAN - ISO 33 - ISAUDIT'
            "
            >-</b-td
          >
          <b-td
            v-if="
              filterType == null || filterType == 'KYNAN - ISO 33 - ISAUDIT'
            "
            class="td-width-dynamyque"
            >{{ gettotalFraisBureauEtude.total_be }}
          </b-td>
          <b-td
            v-if="
              filterType == null || filterType == 'KYNAN - ISO 33 - ISAUDIT'
            "
            class="td-width-dynamyque"
            >-
          </b-td>
          <b-td
            v-if="
              filterType == null || filterType == 'KYNAN - ISO 33 - ISAUDIT'
            "
            class="td-width-dynamyque"
            >-
          </b-td>
          <b-td
            v-if="
              filterType == null || filterType == 'KYNAN - ISO 33 - ISAUDIT'
            "
            class="td-width-dynamyque"
            >-
          </b-td>
          <b-td
            v-if="filterType == null || filterType == 'BE B KYNAN'"
            class="td-width-dynamyque"
            >{{ gettotalFraisBureauEtude.total_kynan }}
          </b-td>
          <b-td
            v-if="filterType == null || filterType == 'BE B KYNAN'"
            class="td-width-dynamyque"
            >-
          </b-td>
          <b-td
            v-if="filterType == null || filterType == 'BE B KYNAN'"
            class="td-width-dynamyque"
            >-
          </b-td>
          <b-td
            v-if="filterType == null || filterType == 'BE B KYNAN'"
            class="td-width-dynamyque"
            >-
          </b-td>
          <b-td
            v-if="filterType == null || filterType == 'BE B ALTEO'"
            class="td-width-dynamyque"
            >{{ gettotalFraisBureauEtude.total_alteo }}
          </b-td>
          <b-td
            v-if="filterType == null || filterType == 'BE B ALTEO'"
            class="td-width-dynamyque"
            >-
          </b-td>
          <b-td
            v-if="filterType == null || filterType == 'BE B ALTEO'"
            class="td-width-dynamyque"
            >-
          </b-td>
          <b-td
            v-if="filterType == null || filterType == 'BE B ALTEO'"
            class="td-width-dynamyque"
            >-
          </b-td>
        </b-tr>
      </template> -->
    </b-table>
    <div v-if="computedTotal > 0" class="style-somme-th-ttc">
      <p class="m-2">
        Total ht:
        {{ computedTotal.toFixed(2).toLocaleString('en-US') }}€ {{ '   ' }}Total
        ttc:

        {{ computedTotalTTC.toFixed(2).toLocaleString('en-US') }}€
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import templateBureauEtude from './templateBureauEtude.vue';
import EditableInput from '../../../../component/EditableInput.vue';
export default {
  props: {
    name: { required: true },
    filteryear: { required: true },
    filterMonth: { required: true },
    filterFiliale: { required: true },
    filterType: { required: true },
    filterStatut: { required: true },
    filterWeek: { required: true },
    filterDepot: { required: true },
    searchValue: { required: true },
    page: { required: true },
    perPage: { required: true },
    dataToUse: { required: true },
    isfilterChanged: { required: true },
    computedCheckedRows: { required: true }
  },
  data() {
    return {
      fields: [
        {
          key: 'check_all',
          label: '',
          tdClass: 'color',
          thClass: 'th-color',
          for: 'all'
        },
        {
          key: 'details',
          label: '',
          tdClass: 'details-column-frais',
          thClass: 'th-details-column-frais',
          for: 'all'
        },
        {
          key: 'installeur',
          label: 'Filiale Installateur',
          thClass: 'th-installeur-column-frais',
          tdClass: 'installeur-column-frais',
          for: 'all'
        },
        {
          key: 'numero_dossier',
          label: 'Numéro de dossier',
          for: 'all'
        },
        {
          key: 'bureau_etude_type',
          label: "Bureau d'etude",
          for: 'bureau_etude'
        },
        {
          key: 'bureau_etude',
          label: "Montant Bureau d'etude",
          thClass: 'bg-color-th-bureau-etude',
          for: 'bureau_etude'
        },
        {
          key: 'bureau_etude_type_paiement',
          label: 'Type de paiement',
          thClass: 'bg-color-th-bureau-etude',
          for: 'bureau_etude'
        },
        {
          key: 'statue_bureau_etude',
          label: 'Statut',
          thClass: 'bg-color-th-bureau-etude',
          for: 'bureau_etude'
        },

        {
          key: 'date_bureau_etude',
          label: 'Date',
          thClass: 'bg-color-th-bureau-etude',
          for: 'bureau_etude'
        },
        {
          key: 'facture_bureau_etude_numero',
          label: 'Numéro de facture',
          thClass: 'bg-color-th-bureau-etude',
          for: 'bureau_etude'
        },
        {
          key: 'statue_installateur',
          label: 'Statut de paiement installateur',
          thClass: 'bg-color-th-bureau-etude',
          for: 'bureau_etude'
        },
        {
          key: 'email_sent_at',
          label: "Date d'envoie",
          thClass: 'bg-color-th-bureau-etude',
          for: 'bureau_etude'
        },
        {
          key: 'lot',
          label: 'Numéro de lot',
          thClass: 'bg-color-th-bureau-etude',
          for: 'bureau_etude'
        },
        {
          key: 'av_numero',
          label: 'Numéro de facture avoir',
          thClass: 'bg-color-th-bureau-etude',
          for: 'bureau_etude'
        },
        // {
        //   key: 'mail_send_at',
        //   label: "Date d'envoie",
        //   thClass: 'bg-color-th-bureau-etude',
        //   for: 'bureau_etude'
        // },
        //
        {
          key: 'binifis_kynan',
          label: 'Montant bonification KYNAN',
          thClass: 'bg-color-th-technique',
          for: 'kynan'
        },
        {
          key: 'kynan_type_paiement',
          label: 'Type de paiement',
          thClass: 'bg-color-th-technique',
          for: 'kynan'
        },
        {
          key: 'statue_kynan',
          label: 'Statut',
          thClass: 'bg-color-th-technique',
          for: 'kynan'
        },
        {
          key: 'date_kynan',
          label: 'Date',
          thClass: 'bg-color-th-technique',
          for: 'kynan'
        },
        {
          key: 'facture_kynan_numero',
          label: 'Numéro de facture',
          thClass: 'bg-color-th-technique',
          for: 'kynan'
        },
        {
          key: 'kynan_statue_installateur',
          label: 'Statut de paiement installateur',
          thClass: 'bg-color-th-technique',
          for: 'kynan'
        },
        {
          key: 'kynan_email_sent_at',
          label: "Date d'envoie",
          thClass: 'bg-color-th-technique',
          for: 'kynan'
        },
        {
          key: 'kynan_lot',
          label: 'Numéro de lot',
          thClass: 'bg-color-th-technique',
          for: 'kynan'
        },
        {
          key: 'kynan_av_numero',
          label: 'Numéro de dacture avoir',
          thClass: 'bg-color-th-technique',
          for: 'kynan'
        },
        // {
        //   key: 'kynan_mail_send_at',
        //   label: "Date d'envoie",
        //   thClass: 'bg-color-th-technique',
        //   for: 'kynan'
        // },
        //
        {
          key: 'binifis_alteo',
          label: 'Montant bonification ALTEO',
          thClass: 'bg-color-th-alger',
          for: 'alteo'
        },
        {
          key: 'alteo_type_paiement',
          label: 'Type de paiement',
          thClass: 'bg-color-th-alger',
          for: 'alteo'
        },
        {
          key: 'statue_alteo',
          label: 'Statut',
          thClass: 'bg-color-th-alger',
          for: 'alteo'
        },
        {
          key: 'date_alteo',
          label: 'Date',
          thClass: 'bg-color-th-alger',
          for: 'alteo'
        },
        {
          key: 'facture_alteo_numero',
          label: 'Numéro de facture',
          thClass: 'bg-color-th-alger',
          for: 'alteo'
        },
        {
          key: 'alteo_statue_installateur',
          label: 'Statut de paiement installateur',
          thClass: 'bg-color-th-alger',
          for: 'alteo'
        },
        {
          key: 'alteo_email_sent_at',
          label: "Date d'envoie",
          thClass: 'bg-color-th-alger',
          for: 'alteo'
        },
        {
          key: 'alteo_lot',
          label: 'Numéro de lot',
          thClass: 'bg-color-th-alger',
          for: 'alteo'
        },
        {
          key: 'alteo_av_numero',
          label: 'Numéro de facture avoir',
          thClass: 'bg-color-th-alger',
          for: 'alteo'
        }
      ],
      sortBy: 'name',
      computedBureauDetudeList: [
        { value: 'ISO 33', full_name: 'ISO 33' },
        { value: 'KYNAN', full_name: 'KYNAN' },
        { value: 'ISODIT', full_name: 'ISODIT' },
        { value: 'EFFIMAX', full_name: 'EFFIMAX' },
        { value: 'CWF CONSULTING', full_name: 'CWF CONSULTING' }
      ],
      checkAll: false,
      typeArray: [
        'bureau_detude',
        'binifis_kynan',
        'binifis_alteo',
        'EFFIMAX',
        'CWF CONSULTING'
      ]
    };
  },
  computed: {
    ...mapGetters([
      'getFraisBureauEtude',
      'cantExport',
      'cantCreate',
      'cantUpdate',
      'getUserData',
      'gettotalFraisBureauEtude',
      'getSuccessSendMails',
      'getErrorOneDay',
      'getErrorInvalidElmail',
      'getErrorHasntEmail',
      'getOtherErrorMail',
      'getErrorHasentTemplateGlobal',
      'getErrorHasentTemplateEmailGlobal',
      'cantSend',
      'isResponsableFiliale',
      'isResponsableAlteao',
      'isUserIso33',
      'isUserIsodit',
      'isUserEffimax',
      'isUserCWFCONSULTING',
      'isUserBe',
      'responsableValidation'
    ]),
    computedEditableStatutInstallateurFrais: function() {
      return function(data) {
        if (data) {
          let prefix = data.column != null ? '_' + data.column : '';
          let prefixstatut = data.column != null ? data.column + '_' : '';
          if (
            this.cantUpdate(this.name) &&
            (this.isResponsableFiliale || this.isSuperAdmin) &&
            data.item['statue' + prefix] != 'paye' &&
            data.item['statue' + prefix] != 'annulee' &&
            data.item['statue' + prefix] != 'passive' &&
            data.item[prefixstatut + 'statue_installateur'] != 'paye' &&
            data.item['downloadable' + prefix] == true
          ) {
            return true;
          }
        }
        return false;
      };
    },
    computedFraisBureauEtude() {
      return this.dataToUse;
    },
    computedTotal() {
      let some = 0;
      if (this.filterType != null) {
        for (let i = 0; i < this.computedCheckedRows.length; i++) {
          for (
            let j = 0;
            j < this.computedCheckedRows[i].projects.length;
            j++
          ) {
            if (this.computedCheckedRows[i].projects[j].check == true) {
              some =
                some +
                  Math.round(
                    parseFloat(
                      this.computedCheckedRows[i].projects[j][
                        this.computedColumn
                      ]
                      // .replace(' ', '')
                      // .replace(',', '.')
                    ) * 100
                  ) /
                    100 || 0;
            }
          }
        }
      }
      return Math.round(some * 100) / 100;
    },
    computedTotalTTC() {
      let some = 0;
      if (this.filterType != null) {
        for (let i = 0; i < this.computedCheckedRows.length; i++) {
          for (
            let j = 0;
            j < this.computedCheckedRows[i].projects.length;
            j++
          ) {
            if (this.computedCheckedRows[i].projects[j].check == true) {
              some =
                some +
                  Math.round(
                    parseFloat(
                      this.computedCheckedRows[i].projects[j][
                        this.computedColumn
                      ]
                      // .replace(' ', '')
                      // .replace(',', '.')
                    ) *
                      1.2 *
                      100
                  ) /
                    100 || 0;
            }
          }
        }
      }
      return Math.round(some * 100) / 100;
    },
    computedColumn() {
      switch (this.filterType) {
        case 'BE B KYNAN':
          return 'binifis_kynan';
        case 'KYNAN - ISO 33 - ISAUDIT':
          return 'montant';
        case 'BE B ALTEO':
          return 'binifis_alteo';

        default:
          return this.filterType;
      }
    },
    isSuperAdmin() {
      return (
        this.getUserData &&
        this.getUserData.role &&
        this.getUserData.role == 'admin'
      );
    },
    isResponsableAlteo() {
      return (
        this.getUserData &&
        this.getUserData.role &&
        this.getUserData.role == 'responsable_acteo'
      );
    },

    computedValidationList() {
      return [
        { value: 'a paye', full_name: 'Facture à payer' },
        { value: 'annulee', full_name: 'Facture annulée' },
        { value: 'paye', full_name: 'Facture payée' },
        {
          value: 'facture non validée',
          full_name: 'Facture non validée',
          $isDisabled: true
        }
      ];
    },
    computedValidationListInstallateur() {
      return [
        { value: 'a paye', full_name: 'Facture à payer' },
        { value: 'paye', full_name: 'Facture payée' }
      ];
    },
    computedFields() {
      switch (this.filterType) {
        case 'KYNAN - ISO 33 - ISAUDIT':
          return this.fields.filter(
            item => item.for == 'all' || item.for == 'bureau_etude'
          );
        case 'BE B KYNAN':
          return this.fields.filter(
            item => item.for == 'all' || item.for == 'kynan'
          );
        case 'BE B ALTEO':
          return this.fields.filter(
            item => item.for == 'all' || item.for == 'alteo'
          );
        default:
          return this.fields;
      }
    },
    computedHeaderVisibility() {
      return function(data) {
        return (
          data == 'binifis_kynan' ||
          data == 'binifis_alteo' ||
          data == 'bureau_etude' ||
          data == 'statue_bureau_etude' ||
          data == 'date_bureau_etude' ||
          data == 'binifis_kynan' ||
          data == 'statue_kynan' ||
          data == 'binifis_alteo' ||
          data == 'statue_alteo' ||
          data == 'date_alteo' ||
          data == 'date_kynan' ||
          data == 'facture_kynan_numero' ||
          data == 'facture_alteo_numero' ||
          data == 'facture_bureau_etude_numero' ||
          data == 'statue_installateur' ||
          data == 'kynan_statue_installateur' ||
          data == 'alteo_statue_installateur'
        );
      };
    },
    computedShowDetailsColomn() {
      return function(data) {
        if (data && data.key) {
          if (this.filterType == null || this.filterType == data.value) {
            return true;
          } else {
            return false;
          }
        } else {
          if (this.filterType == null || this.filterType == data) {
            return true;
          } else {
            return false;
          }
        }
      };
    },
    computedCantUpdate() {
      return (
        this.cantExport(this.name) &&
        !this.isSuperAdmin &&
        (this.isResponsableAlteo || this.responsableValidation)
      );
    },
    computedCantCreate() {
      return (
        this.cantCreate(this.name) &&
        !this.isSuperAdmin &&
        (this.isResponsableAlteo || this.responsableValidation)
      );
    },
    computedFactureVide() {
      return function(montant, statue) {
        if (
          statue == 'facture non validée' &&
          (montant == '0.00' ||
            montant == '0,00' ||
            montant == '0' ||
            montant == 0)
        ) {
          return true;
        }
        return false;
      };
    }
  },
  methods: {
    ...mapActions([
      'exportPdfFactureUploadedTypeBureauEtude',
      'updateBeType',
      'updateStatueFraisBureauEtude',
      'uploadBureauEtudeTemplateInServieVisionFiliale',
      'getBureauEtudeTemplateAndFiledsDataToUpdate',
      'updateMontantFraisBureauEtudeB',
      'downloadExtraitInstalleurAction',
      'downloadExtraitSupportServiceAction',
      'updateStatueInstallateurFraisBureauEtude',
      'sendMailGestionGlobalFrais',
      'updattypePaiementFrais',
      'getDataForRowFraisB'
    ]),
    getDataForRow(data) {
      data.toggleDetails();
      if (data.detailsShowing == false) {
        this.getDataForRowFraisB({
          item: data.item,
          year: this.filteryear,
          month: this.filterMonth,
          filiale: this.filterFiliale,
          search: this.searchValue,
          type: this.filterType,
          week: this.filterWeek,
          depot: this.filterDepot,
          statue: this.filterStatut,
          page: this.page,
          perPage: this.perPage
        });
      }
    },
    rowClass(item, rowType) {
      if (rowType === 'row-details') {
        return 'subLigneEnCourDeValidation';
      }
      return 'ligneEnCourDeValidation';
    },
    handleDownloadExtraitInstalleur(item) {
      if (this.filterMonth != null && this.filterType == null) {
        this.$confirm(
          '',
          'Êtes-vous sur de vouloir télecharger tous les extraits pour ' +
            item +
            ' ?',
          'warning'
        )
          .then(() => {
            this.downloadExtraitInstalleurAction({
              year: this.filteryear,
              month: this.filterMonth,
              filiale: item,
              type: this.typeArray
            });
          })
          .catch(() => {});
      }
    },
    handleDownloadExtraitSupportService(item) {
      if (this.filterMonth != null) {
        if (item.type == 'bureau_detude') {
          let typeArray = [
            'bureau_detude_iso_audit',
            'bureau_detude_iso_33',
            'bureau_detude_energie_invest',
            'bureau_detude_kynan'
          ];
          for (let i = 0; i < typeArray.length; i++) {
            this.downloadExtraitSupportServiceAction({
              year: this.filteryear,
              month: this.filterMonth,
              filiale: item.item.name,
              type: typeArray[i]
            });
          }
        } else {
          this.downloadExtraitSupportServiceAction({
            year: this.filteryear,
            month: this.filterMonth,
            filiale: item.item.name,
            type: item.type
          });
        }
      }
    },
    async handleChangeSwitch(payload) {
      const response = await this.updateBeType(payload);
      if (response && payload.item.downloadable == true) {
        this.$emit('actualiserBenefis');
        this.$alert(
          '',
          "cette facture déjà valider, s'il vous plaît revalide votre facture",
          'info'
        ).then(() => {});
      }
    },
    async downloadFacture(payload) {
      await this.exportPdfFactureUploadedTypeBureauEtude({
        year: this.filteryear,
        filiale: payload.data.installeur,
        month: this.filterMonth,
        type: payload.type,
        nom_prenom: payload.data.nom_prenom,
        id: payload.facture
          ? payload.data[payload.facture]
          : payload.data.facture_id
      });
    },
    async downloadFactureAvoir(payload) {
      await this.exportPdfFactureUploadedTypeBureauEtude({
        year: this.filteryear,
        filiale: payload.data.installeur,
        month: this.filterMonth,
        type: payload.type,
        nom_prenom: payload.data.nom_prenom,
        id: payload.facture
          ? payload.data[payload.facture]
          : payload.data.facture_av_id,
        av: 'oui'
      });
    },
    async sendMail(payload) {
      var bodyFormData = new FormData();
      bodyFormData.append(
        'ids[0]',
        payload.facture
          ? payload.data[payload.facture]
          : payload.data.facture_id
      );
      const response = await this.sendMailGestionGlobalFrais(bodyFormData);
      if (response.succes) {
        if (this.getErrorOneDay?.facture?.length > 0) {
          this.flashMessage.show({
            status: 'error',
            title: this.getErrorOneDay?.error,
            clickable: true,
            time: 0,
            message: this.getErrorOneDay?.facture
          });
        }

        if (this.getSuccessSendMails?.facture?.length > 0) {
          this.flashMessage.show({
            status: 'success',
            title: this.getSuccessSendMails?.success,
            clickable: true,
            time: 0,
            message: this.getSuccessSendMails?.facture
          });
        }
        if (this.getErrorInvalidElmail?.facture?.length > 0) {
          this.flashMessage.show({
            status: 'error',
            title: this.getErrorInvalidElmail?.error,
            clickable: true,
            time: 0,
            message: this.getErrorInvalidElmail?.facture
          });
        }
        if (this.getErrorHasntEmail?.facture?.length > 0) {
          this.flashMessage.show({
            status: 'error',
            title: this.getErrorHasntEmail?.error,
            clickable: true,
            time: 0,
            message: this.getErrorHasntEmail?.facture
          });
        }
        if (this.getErrorHasentTemplateEmailGlobal?.facture?.length > 0) {
          this.flashMessage.show({
            status: 'error',
            title: this.getErrorHasentTemplateEmailGlobal?.error,
            clickable: true,
            time: 0,
            message: this.getErrorHasentTemplateEmailGlobal?.facture
          });
        }
        if (this.getErrorHasentTemplateGlobal?.facture?.length > 0) {
          this.flashMessage.show({
            status: 'error',
            title: this.getErrorHasentTemplateGlobal?.error,
            clickable: true,
            time: 0,
            message: this.getErrorHasentTemplateGlobal?.facture
          });
        }
      } else {
        this.flashMessage.show({
          status: 'error',
          title: 'ERROR',
          clickable: true,
          time: 0,
          message: this.getOtherErrorMail
        });
      }
    },
    checkAllFunction() {
      this.getFraisBureauEtude.map(item => {
        item.check = this.checkAll;
        item.projects.map(itemD => (itemD.check = this.checkAll));
      });
    },
    checkAllFunctionDetails(data) {
      data.map(item => (item.check = this.checkAll));
    },
    checkAllVerif(item) {
      if (item.check == false && this.checkAll == true) {
        this.checkAll = false;
      }
      item.projects.map(itemD => (itemD.check = item.check));
    },
    checkAllVerifDetails(data, item) {
      if (item.check == false && data.check == true) {
        data.check = false;
      }
      if (data.check == false && this.checkAll == true) {
        this.checkAll = false;
      }
    }
  },
  components: {
    templateBureauEtude,
    EditableInput,
    iconInfoComponent: () => import('../iconInfoComponent.vue'),
    paiementColumnComponent: () => import('../paiementColumnComponent.vue')
  },
  watch: {
    isfilterChanged: function() {
      this.sommeSelectedCopie = 0;
    }
  }
};
</script>

<style lang="scss" scoped>
.stlye-table-frais-be {
  width: 99%;
  display: contents;
  .customTableFraisBureauEtude {
    max-height: calc(100vh - 220px) !important;
    height: calc(100vh - 220px) !important;
    margin-bottom: 0px;
    width: 99%;
    overflow-x: auto;
    padding: 4px !important;
    width: 99%;
  }
  .icon-plus {
    margin-right: 7px;
    color: #ffff;
    font-size: 10px;
    &:hover {
      color: #4d4bac;
    }
  }
  .icon-details {
    font-size: 13px;
    color: #8d8cb7;
    &:hover {
      color: #4d4bac;
    }
  }
  .block-service-style {
    span {
      width: 50%;
    }
    display: flex;
    justify-content: space-between;
    text-align: left;
  }
  .border-botom {
    border-bottom: 1px solid #f6f5fb;
  }
}
.span-extrait {
  cursor: pointer;
}
.envelop-icon-container {
  background-color: #8d8cb7;
  border-radius: 15px;
  width: 13px;
  height: 13px;
}
.envelop-icon {
  width: 7px;
  color: #ececec;
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 2px;
}
// .envelop-icon-container:hover {
//   background-color: #4d4bac;
// }
.background-email-sent {
  background-color: #42a442;
  border-radius: 15px;
  width: 13px;
  height: 13px;
}
.style-somme-th-ttc {
  font-family: 'Montserrat', sans-serif;
  background-color: #d0e4f5;
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: auto;
  height: 35px;
  border-radius: 30px;
  z-index: 120;
}
.button-export-style-avoir {
  color: #e4261b;
  &:hover,
  &:focus {
    color: #e4261b;
  }
}
</style>
<style lang="scss">
.color {
  border-left: none !important;
}
.customTableFraisBureauEtude .table.b-table > caption {
  display: table-footer-group !important;
}
.table-rapport-style .customTableFraisBureauEtude {
  td,
  th {
    font-size: 8.5px;
  }
  .color {
    border-left: none !important;
  }
  td {
    width: calc(100vh / 4);
    line-height: 10px;
    span {
      cursor: pointer;
    }
  }
}
.table-rapport-style .customTableFraisBureauEtudeRezise {
  td,
  th {
    min-width: 100px;
    width: 100px;
  }
}
.bg-color-total-be {
  background-color: #dfdef4;
}
.justify-content-sb-bureau-etude {
  display: block !important;
  line-height: 11px !important;
}
</style>
