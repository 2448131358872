<template>
  <div class="gestion-facture-bureau-etude">
    <div class="entete">
      <div class="box-all-filter">
        <div class="box-label-champ mr-2">
          <div class="label-box-style label-box-style-w-1 p-4-5">
            <span class="title-tabel">Année</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select b-form-select-new-style-w-1"
            v-model="filteryear"
            :options="ListYear"
            @change="handleChangeYear"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style  label-box-style-w-1">
            <span class="title-tabel">Mois</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select  w-63-px"
            v-model="filterMonth"
            :options="[
              { value: null, text: 'Tous' },
              ...computedGetMonthForYearTh
            ]"
            @change="handleChangeMonth"
            text-field="text"
            value-field="value"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style w-46-px">
            <span class="title-tabel">Semaine</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select w-63-px"
            v-model="filterWeek"
            :options="[
              { value: null, text: 'Tous' },
              ...getcomputedListWeek(filterMonth)
            ]"
            text-field="text"
            value-field="value"
            @change="handleChangeWeek"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style w-93-px">
            <span class="title-tabel">Filiale Installateur</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select w-63-px"
            v-model="filterFiliale"
            :options="[
              { value: null, text: 'Tous' },
              ...getcomputedFiliale(filterMonth, filterWeek)
            ]"
            text-field="text"
            value-field="value"
            @change="handleChangeFiliale"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style  label-box-style-w-1">
            <span class="title-tabel">Dépôt</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select  "
            v-model="filterDepot"
            :options="[
              { value: null, text: 'Tous' },
              ...getcomputedDepots(filterMonth, filterWeek, filterFiliale)
            ]"
            text-field="text"
            value-field="value"
            @change="handleChangeDepot"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2" v-if="computedRole">
          <div class="label-box-style  w-78-px">
            <span class="title-tabel">Filiale Support</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select  w-78-px"
            v-model="filterType"
            :options="computedListType"
            text-field="text"
            value-field="value"
            @change="handlechangeFilialeSupport(filterType)"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2" v-if="filterType != null">
          <div class="label-box-style  w-46-px">
            <span class="title-tabel">Statut</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select  w-78-px"
            v-model="filterStatut"
            :options="[
              { full_name: 'Tous', valus: null },
              ...computedValidationList
            ]"
            text-field="full_name"
            value-field="value"
            @change="handleFilter"
          ></b-form-select>
        </div>
        <!-- <div class="error-message mr-2 ml-2">
          <div v-if="getErrorFraisBureauEtude" class="error">
            <ul v-if="Array.isArray(getErrorFraisBureauEtude)" class="mb-0">
              <li v-for="(e, index) in getErrorFraisBureauEtude" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ getErrorFraisBureauEtude }}</div>
          </div>
        </div> -->
        <div
          v-if="getLoadingFraisBureauEtude"
          class="chargement chargement-loading-icon"
        >
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="box-end-position">
          <search :value="searchValue" @changeSearchValue="changeSearchValue" />
          <global-validation-bureau-detude
            v-if="
              cantCreate(this.$options.name) &&
                filterType != null &&
                !isSuperAdmin &&
                computedCheckedRows.length &&
                filterType != 'KYNAN - ISO 33 - ISAUDIT'
            "
            :month="filterMonth"
            :year="filteryear"
            :type="filterType"
            @reloadData="reloadData"
            :dataToUse="computedFraisBureauEtude"
          />
          <validation-statue-installateur
            v-if="
              cantUpdate(this.$options.name) &&
                computedCheckedRows.length &&
                filterType
            "
            :filterType="filterType"
            :dataToUse="computedFraisBureauEtude"
            @reloadData="reloadData"
            interfaceFrais="be"
          />
          <generation-facture-avoir-frais
            :interfaceName="this.$options.name"
            :filterType="filterType"
            :dataToUse="computedFraisBureauEtude"
            @reloadData="reloadData"
          />
          <extractionBE
            :filteryear="filteryear"
            :filterMonth="filterMonth"
            :filterType="filterType"
            :dataToUse="computedFraisBureauEtude"
            :filter="ComputedFilter"
            :selected="computedCheckedRows.length"
          />
          <div v-if="getLoadingMail" class="chargement chargement-loading-icon">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div
            v-if="
              !getLoadingMail &&
                computedCheckedRows &&
                computedCheckedRows.length > 0
            "
          >
            <sendMailBureauEtude
              :type="filterType"
              :dataToUse="computedFraisBureauEtude"
              v-if="!isSuperAdmin && cantSend($options.name)"
            />
          </div>
          <!-- <extractionBEInvalid
            v-if="filterType"
            :filteryear="filteryear"
            :filterMonth="filterMonth"
            :filterType="filterType"
            :dataToUse='computedFraisBureauEtude'

          /> -->
        </div>
      </div>
    </div>
    <div class="body-box-rapport ">
      <div class="table-rapport-style w-100-p">
        <frais-bureau-detude-be
          :name="$options.name"
          :filteryear="filteryear"
          :filterMonth="filterMonth"
          :filterFiliale="filterFiliale"
          :filterType="filterType"
          :filterStatut="filterStatut"
          :dataToUse="computedFraisBureauEtude"
          :isfilterChanged="isfilterChanged"
          :searchValue="searchValue"
          :filterWeek="filterWeek"
          :filterDepot="filterDepot"
          :page="page"
          :perPage="perPage"
          :computedCheckedRows="computedCheckedRows"
        />
        <div class=" footer-style">
          <b-pagination
            v-model="page"
            :total-rows="getfraisBureauEtudeTotal"
            :per-page="perPage"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination>
          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="perPage"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import FraisBureauDetudeBe from './component/BE/FraisBureauDetudeBe.vue';
export default {
  components: {
    FraisBureauDetudeBe,
    search: () => import('@/views/component/SearchInputComponent.vue'),
    extractionBE: () => import('./component/BE/extractionBE.vue'),
    // extractionBEInvalid: () => import('./component/extractionBEInvalid.vue'),
    globalValidationBureauDetude: () =>
      import('./component/BE/globalValidationBureauDetude.vue'),
    sendMailBureauEtude: () => import('./component/BE/sendMailBureauEtude.vue'),
    validationStatueInstallateur: () =>
      import('./component/ValidationStatueInstallateur.vue'),
    generationFactureAvoirFrais: () =>
      import('./component/generationFactureAvoirFrais.vue')
  },
  name: 'Gestion-Bureau-Etude',
  data() {
    return {
      page: 1,
      perPage: 20,
      perPageList: [
        { value: 5, text: 5 },
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 30, text: 30 }
      ],
      filterStatut: null,
      flashMessageId: null,
      filterWeek: null,
      filterDepot: null,
      filteryear: null,
      ListYear: [],
      filterMonth: null,
      filterFiliale: null,
      searchValue: null,
      filterType: null,
      isfilterChanged: null,
      listType: [
        { value: 'KYNAN - ISO 33 - ISAUDIT', text: 'Bureau detude' },
        { value: 'BE B KYNAN', text: 'KYNAN' },
        { value: 'BE B ALTEO', text: 'ALTEO' }
      ]
    };
  },
  computed: {
    ...mapGetters([
      'getMonthForYearTh',
      'getOnlineFilialeInVisionFilialeFrais',
      'getLoadingFraisBureauEtude',
      'getErrorFraisBureauEtude',
      'cantCreate',
      'getUserData',
      'getcomputedListWeek',
      'getcomputedFiliale',
      'getcomputedDepots',
      'cantSend',
      'getLoadingMail',
      'getFraisBureauEtude',
      'cantUpdate',
      'isResponsableFiliale',
      'getfraisBureauEtudeTotal'
    ]),
    ComputedFilter() {
      return {
        year: this.filteryear,
        month: this.filterMonth,
        filiale: this.filterFiliale,
        search: this.searchValue,
        type: this.filterType,
        week: this.filterWeek,
        depot: this.filterDepot,
        statue: this.filterStatut,
        type: this.filterType
      };
    },
    computedCheckedRows() {
      return this.getFraisBureauEtude?.filter(k => {
        return k.projects.some(e => e.check == true);
      });
    },
    computedGetMonthForYearTh() {
      const tab = this.getMonthForYearTh.map(item => ({
        value: item.month_name,
        text: item.month_name
      }));
      return tab;
    },
    ComputedGetOnlineFilialeInVisionFilialeFrais() {
      return this.getOnlineFilialeInVisionFilialeFrais.map(item => {
        return {
          value: item,
          text: item
        };
      });
    },
    computedRole() {
      return (
        this.getUserData &&
        this.getUserData.role &&
        (this.getUserData.role == 'admin' ||
          this.getUserData.role == 'responsable_bureau_etude' ||
          this.getUserData.role == 'responsable_acteo')
      );
    },
    computedListType() {
      if (
        this.getUserData &&
        this.getUserData.role &&
        this.getUserData.role == 'responsable_acteo'
      ) {
        return this.listType.filter(
          item =>
            item.value == 'BE B ALTEO' ||
            item.value == 'KYNAN - ISO 33 - ISAUDIT'
        );
      }
      return [{ value: null, text: 'Tous' }, ...this.listType];
    },
    isSuperAdmin() {
      return this.getUserData?.role && this.getUserData.role == 'admin';
    },
    computedValidationList() {
      return [
        { value: 'a paye', full_name: 'Facture à payer' },
        { value: 'annulee', full_name: 'Facture annulée' },
        { value: 'paye', full_name: 'Facture payée' },
        {
          value: 'facture non validée',
          full_name: 'Facture non validée',
          $isDisabled: true
        }
      ];
    },
    computedColumn() {
      switch (this.filterType) {
        case 'KYNAN - ISO 33 - ISAUDIT':
          return 'statue';
        case 'BE B KYNAN':
          return 'statue_kynan';
        case 'BE B ALTEO':
          return 'statue_alteo';
        default:
          return this.filterType;
      }
    },
    computedFraisBureauEtude() {
      return this.getFraisBureauEtude;
    }
  },
  methods: {
    ...mapActions([
      'fetchMonthForYearTH',
      'fetchFraisBureauEtude',
      'updateInterface'
    ]),
    pagination(paginate) {
      this.page = paginate;
      this.handleFilter();
    },
    changePerPage() {
      this.page = 1;
      this.handleFilter();
    },
    handleFilter() {
      this.fetchFraisBureauEtude({
        year: this.filteryear,
        month: this.filterMonth,
        filiale: this.filterFiliale,
        search: this.searchValue,
        type: this.filterType,
        week: this.filterWeek,
        depot: this.filterDepot,
        statue: this.filterStatut,
        type: this.filterType,
        page: this.page,
        perPage: this.perPage
      });
      this.setLocalStorageBurauEtude();
    },
    reloadData() {
      this.checkAll = false;
      this.filterStatut = null;
      this.handleFilter();
      this.sommeSelected = 0;
    },
    changeSearchValue(value) {
      this.searchValue = value;
      this.handleFilter();
    },
    handleChangeYear() {
      this.filterMonth = null;
      this.filterDepot = null;
      this.filterWeek = null;
      // if (!this.isResponsableFiliale) {
      this.filterFiliale = null;
      // }
      this.fetchMonthForYearTH(this.filteryear);
      this.handleFilter();
      this.setLocalStorageBurauEtude();
    },
    handleChangeMonth() {
      this.filterDepot = null;
      this.filterWeek = null;
      // if (!this.isResponsableFiliale) {
      this.filterFiliale = null;
      // }
      this.handleFilter();
      this.setLocalStorageBurauEtude();
    },
    handleChangeWeek() {
      this.filterDepot = null;
      // if (!this.isResponsableFiliale) {
      this.filterFiliale = null;
      // }
      this.handleFilter();
      this.setLocalStorageBurauEtude();
    },
    handleChangeFiliale() {
      this.filterDepot = null;
      this.handleFilter();
      this.setLocalStorageBurauEtude();
    },
    handleChangeDepot() {
      this.handleFilter();
      this.setLocalStorageBurauEtude();
    },
    handlechangeFilialeSupport(filterType) {
      this.isfilterChanged = filterType;
      this.filterStatut = null;
      this.handleFilter();
      this.setLocalStorageBurauEtude();
    },
    checkRole() {
      if (this.getUserData && this.getUserData.role) {
        switch (this.getUserData.role) {
          case 'responsable_acteo':
            this.filterType = 'BE B ALTEO';
            break;
          case 'responsable_filiale':
            this.filterType = 'KYNAN - ISO 33 - ISAUDIT';
            break;
          case 'utilisateur_iso33':
            this.filterType = 'KYNAN - ISO 33 - ISAUDIT';
            break;
          case 'utilisateur_isodit':
            this.filterType = 'KYNAN - ISO 33 - ISAUDIT';
            break;
          case 'utilisateur_effimax':
            this.filterType = 'KYNAN - ISO 33 - ISAUDIT';
            break;
          case 'utilisateur_CWF_CONSULTING':
            this.filterType = 'KYNAN - ISO 33 - ISAUDIT';
            break;
        }
      }
    },
    setLocalStorageBurauEtude() {
      localStorage.setItem(
        'bureau-etude',
        JSON.stringify({
          year: this.filteryear,
          month: this.filterMonth,
          week: this.filterWeek,
          filiale: this.filterFiliale,
          depot: this.filterDepot,
          type: this.filterType,
          statue: this.filterStatut,
          search: this.searchValue
        })
      );
    }
  },
  watch: {
    getUserData: function() {
      this.checkRole();
    },
    getErrorFraisBureauEtude: function() {
      if (this.getErrorFraisBureauEtude != null) {
        const objectFlash = {
          blockClass: 'custom-block-class',
          contentClass: 'custom-content-class',
          message: ''
        };
        if (Array.isArray(this.getErrorFraisBureauEtude)) {
          for (let e in this.getErrorFraisBureauEtude) {
            objectFlash.message =
              objectFlash.message + ' ' + this.getErrorFraisBureauEtude[e];
          }
        } else {
          objectFlash.message = this.getErrorFraisBureauEtude;
        }
        this.flashMessageId = this.flashMessage.show({
          status: 'error',
          title: 'ERROR',
          clickable: true,
          time: 0,
          blockClass: objectFlash.blockClass,
          contentClass: objectFlash.contentClass,
          message: objectFlash.message
        });
      } else {
        this.flashMessage.deleteMessage(this.flashMessageId);
      }
    },
    beforeRouteLeave(to, from, next) {
      this.flashMessage.deleteMessage(this.flashMessageId);
      next();
    }
  },
  async mounted() {
    this.updateInterface('be');
    this.checkRole();
    var courantYear = moment().year();
    this.filteryear = courantYear;
    if (localStorage.getItem('bureau-etude')) {
      this.filteryear = JSON.parse(localStorage.getItem('bureau-etude')).year;
    }
    await this.fetchMonthForYearTH(this.filteryear);
    var courantMonthId = moment().month();
    var courantMonth = this.getMonthForYearTh[courantMonthId]
      ? this.getMonthForYearTh[courantMonthId].month_name
      : this.filterMonth;
    if (localStorage.getItem('bureau-etude')) {
      this.filterMonth = JSON.parse(localStorage.getItem('bureau-etude')).month;
      this.filterDepot = JSON.parse(localStorage.getItem('bureau-etude')).depot;
      this.filterWeek = JSON.parse(localStorage.getItem('bureau-etude')).week;
      this.filterType = JSON.parse(localStorage.getItem('bureau-etude')).type;
      this.filterFiliale = JSON.parse(
        localStorage.getItem('bureau-etude')
      ).filiale;
      this.filterStatut = JSON.parse(
        localStorage.getItem('bureau-etude')
      ).statue;
      this.searchValue = JSON.parse(
        localStorage.getItem('bureau-etude')
      ).search;
    } else {
      this.year = courantYear;
      this.filterMonth = courantMonth;
      this.setLocalStorageBurauEtude();
    }

    for (var i = 2019; i <= courantYear; i++) {
      this.ListYear.push({ value: i, text: i });
    }

    this.handleFilter();
  }
};
</script>

<style scoped lang="scss">
.gestion-facture-bureau-etude {
  .entete {
    padding: 5px 10px;
  }
  .body-box-rapport {
    height: calc(100vh - 174px);
  }
  .facture_block_display {
    display: flex;
    justify-content: center;
  }
}
</style>
