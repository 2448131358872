<template>
  <div class="bureau-detude-validation-frais">
    <font-awesome-icon
      icon="file-code"
      @click.prevent="handleEditTemplate"
      class="icon-style-color"
      title="Valider la facture"
    />
    <!-- facture to upload  template -->
    <b-modal
      no-close-on-backdrop
      ref="uploadFactureTemplateModal"
      id="uploadFactureTemplateModal"
      :title="
        getTemplateBureauEtudeToUpload && getTemplateBureauEtudeToUpload.name
          ? getTemplateBureauEtudeToUpload.name
          : ''
      "
      size="lg"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal"
      modal-class="cutsom-modal-bootstrap cutsom-modal-bootstrap-frais "
    >
      <div
        class="iconClose"
        @click.prevent="hideModal('uploadFactureTemplateModal')"
      >
        <font-awesome-icon icon="times" />
      </div>
      <div class="message text-aligne-center">
        <div
          v-if="getLoadingGetDataTemplateBureauEtude"
          class="chargement-message-calender "
        >
          Chargement en cours...
        </div>
        <div
          v-if="getErrorTemplateBureauEtudeDataToUpploadeed"
          class="error-message"
        >
          <div class="error">
            {{ getErrorTemplateBureauEtudeDataToUpploadeed }}
          </div>
        </div>
      </div>

      <div
        class="bureau-de-etude-template-block-style"
        v-if="getTemplateBureauEtudeToUpload"
        id="hmt_block_id"
      >
        <div class="input-fields-style-for-template">
          <div class="form-actions mt-3 mb-5 text-aligne-center">
            <b-button
              @click="handleUpload"
              class="button-succes-style ml-2"
              size="sm"
              variant="success"
            >
              Valider
              <font-awesome-icon icon="arrow-alt-circle-right" class="ml-2" />
            </b-button>
            <div class="message text-aligne-center">
              <div v-if="loadingUpload" class="chargement-message-calender ">
                Chargement en cours...
              </div>
              <div v-if="errorUpload" class="error-message">
                <div class="error">{{ errorUpload }}</div>
              </div>
            </div>
          </div>
          <div class="fields-input">
            <div></div>
            <div>
              <b-form-group
                label="Date de Facture"
                label-for="title-template-swift"
                class=" input-modal-champ "
              >
                <date-picker
                  value-type="format"
                  format="DD-MM-YYYY"
                  type="date"
                  :value="getTemplateBureauEtudeToUpload.date"
                  class="inputDateTemplate"
                  @input="
                    changeFieldsValue({
                      key: 'date_id',
                      value: getTemplateBureauEtudeToUpload.date,
                      e: $event,
                      champName: 'date'
                    })
                  "
                  @focus="
                    focusInFields({
                      key: 'date_id'
                    })
                  "
                ></date-picker>
              </b-form-group>
              <b-form-group
                label="Date de reglement"
                label-for="title-template-swift"
                class=" input-modal-champ "
              >
                <date-picker
                  value-type="format"
                  format="DD-MM-YYYY"
                  type="date"
                  :value="getTemplateBureauEtudeToUpload.date_reglement"
                  class="inputDateTemplate"
                  @input="
                    changeFieldsValue({
                      key: 'date_reglement_id',
                      value: getTemplateBureauEtudeToUpload.date_reglement,
                      e: $event,
                      champName: 'date_reglement'
                    })
                  "
                  @focus="
                    focusInFields({
                      key: 'date_reglement_id'
                    })
                  "
                ></date-picker>
              </b-form-group>
            </div>
            <div>
              <b-form-group
                label="Description "
                label-for="title-template-description"
                class=" input-modal-champ "
              >
                <b-form-textarea
                  id="title-template-description"
                  v-model="getTemplateBureauEtudeToUpload.description"
                  @input="
                    changeFieldsValue({
                      key: 'description_id',
                      value: getTemplateBureauEtudeToUpload.description
                    })
                  "
                  @focus="
                    focusInFields({
                      key: 'description_id'
                    })
                  "
                  rows="5"
                ></b-form-textarea>
              </b-form-group>
            </div>
            <!-- <div>
              <b-form-group
                label="IBAN "
                label-for="title-template-iban"
                class=" input-modal-champ "
              >
                <b-form-input
                  autocomplete="off"
                  id="title-template-iban"
                  v-model="getTemplateBureauEtudeToUpload.iban"
                  @input="
                    changeFieldsValue({
                      key: 'iban_id',
                      value: getTemplateBureauEtudeToUpload.iban
                    })
                  "
                  @focus="
                    focusInFields({
                      key: 'iban_id'
                    })
                  "
                  disabled
                ></b-form-input>
              </b-form-group>
            </div>
            <div>
              <b-form-group
                autocomplete="off"
                label="Swift "
                label-for="title-template-swift"
                class=" input-modal-champ "
              >
                <b-form-input
                  id="title-template-swift"
                  v-model="getTemplateBureauEtudeToUpload.swift"
                  @input="
                    changeFieldsValue({
                      key: 'swift_id',
                      value: getTemplateBureauEtudeToUpload.swift
                    })
                  "
                  @focus="
                    focusInFields({
                      key: 'swift_id'
                    })
                  "
                  disabled
                ></b-form-input>
              </b-form-group>
            </div> -->
            <b-col col xs="12" sm="12" md="12">
              <!-- Rib  --><b-form-group
                label="Rib"
                label-for="rib-template-select"
              >
                <multiselect
                  v-model="rib"
                  :options="getTemplateBureauEtudeToUpload.ribs"
                  :multiple="false"
                  label="rib"
                  track-by="rib"
                  required
                  class="b-form-select-raduis"
                  :showLabels="false"
                >
                </multiselect>
              </b-form-group>
            </b-col>
            <div class="message text-aligne-center">
              <div v-if="errorUploadRib" class="error-message-rib">
                <div class="error">{{ errorUploadRib }}</div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="hmt_template_block_id"
          class="template-style"
          v-if="getTemplateBureauEtudeToUpload"
          v-html="getTemplateBureauEtudeToUpload.template"
        ></div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    year: { required: true },
    month: { required: true },
    item: { required: true },
    type: { required: true },
    updateFunction: { required: true },
    getTemplateFunction: { required: true },
    sendMailDate: { default: null }
  },
  data() {
    return {
      loadingUpload: false,
      errorUpload: null,
      rib: null,
      errorUploadRib: null
    };
  },
  methods: {
    ...mapActions([
      'getBureauEtudeTemplateAndFiledsDataToUpdate',
      'setDaraTemplateBureauEtudeUploded'
    ]),
    async handleEditTemplate() {
      this.$refs['uploadFactureTemplateModal'].show();
      const response = await this.getTemplateFunction({
        type: this.type,
        year: this.year,
        month: this.month,
        filiale: this.item.installeur,
        nom_prenom: this.item.nom_prenom,
        column: this.item.BE_TYPE,
        project_id: this.item.id,
        dev: 'yes'
      });
      if (response) {
        let now = new Date();
        let dateFormate = moment(now).format('DD-MM-YYYY');
        if (
          this.getTemplateBureauEtudeToUpload.date == null ||
          this.getTemplateBureauEtudeToUpload.date == ''
        ) {
          document.getElementById('date_id').innerHTML = dateFormate;
          this.getTemplateBureauEtudeToUpload.date = dateFormate;
        }
        if (
          this.getTemplateBureauEtudeToUpload.date_reglement == null ||
          this.getTemplateBureauEtudeToUpload.date_reglement == ''
        ) {
          document.getElementById('date_reglement_id').innerHTML = dateFormate;

          this.getTemplateBureauEtudeToUpload.date_reglement = dateFormate;
        }
        //
        this.rib = null;
        if (
          this.getTemplateBureauEtudeToUpload.swift != null &&
          this.getTemplateBureauEtudeToUpload.iban != null &&
          this.getTemplateBureauEtudeToUpload.ribs.some(
            data => data.swift === this.getTemplateBureauEtudeToUpload.swift
          ) &&
          this.getTemplateBureauEtudeToUpload.ribs.some(
            data => data.iban === this.getTemplateBureauEtudeToUpload.iban
          )
        ) {
          this.rib = {
            swift: this.getTemplateBureauEtudeToUpload.swift,
            iban: this.getTemplateBureauEtudeToUpload.iban,
            rib:
              this.getTemplateBureauEtudeToUpload.iban +
              ' ' +
              this.getTemplateBureauEtudeToUpload.swift
          };
        } else if (
          !this.getTemplateBureauEtudeToUpload.ribs.some(
            data => data.swift === this.getTemplateBureauEtudeToUpload.swift
          ) &&
          !this.getTemplateBureauEtudeToUpload.ribs.some(
            data => data.iban === this.getTemplateBureauEtudeToUpload.iban
          ) &&
          this.getTemplateBureauEtudeToUpload.swift != null &&
          this.getTemplateBureauEtudeToUpload.iban != null
        ) {
          this.rib = null;
          this.errorUploadRib =
            'Erreur ! RIB ( ' +
            this.getTemplateBureauEtudeToUpload.iban +
            ' ' +
            this.getTemplateBureauEtudeToUpload.swift +
            " ) n'est pas disponible , Merci de contacter l'administrateur .";
        }
      }
      //
    },
    async handleUpload() {
      this.loadingUpload = true;
      this.errorUpload = null;
      this.errorUploadRib = null;
      if (this.rib.iban == null && this.rib.swift == null) {
        this.loadingUpload = false;
        this.errorUpload = null;
        this.errorUpload = 'Une erreur est servenue ! RIB obligatoire';
      } else {
        const response = await this.updateFunction({
          year: this.year,
          filiale: this.item.installeur,
          month: this.month,
          type: this.type,
          description: this.getTemplateBureauEtudeToUpload.description,
          iban: this.getTemplateBureauEtudeToUpload.iban,
          iban: this.rib.iban,
          swift: this.rib.swift,
          num_facture: this.getTemplateBureauEtudeToUpload.num_facture,
          item: this.item,
          date: this.getTemplateBureauEtudeToUpload.date,
          date_reglement: this.getTemplateBureauEtudeToUpload.date_reglement,
          column: this.item.BE_TYPE,
          nom_prenom: this.item.nom_prenom,
          project_id: this.item.id
        });

        if (response.succes) {
          this.loadingUpload = false;
          this.errorUpload = null;
          this.hideModal('uploadFactureTemplateModal');
        } else {
          this.errorUpload = response.error;
          this.loadingUpload = false;
        }
      }
    },
    changeFieldsValue(item) {
      this[item.champName] = item.e;
      if (item && item.e) {
        this.getTemplateBureauEtudeToUpload[item.champName] = item.e;
        document.getElementById(item.key).innerHTML = item.e;
      } else {
        document.getElementById(item.key).innerHTML = item.value;
      }
    },
    focusInFields(item) {
      var myElement = document.getElementById(item.key);
      var rect = myElement.getBoundingClientRect();
      document.getElementById('hmt_template_block_id').scrollTop =
        rect.top + window.pageYOffset;
    },
    resetModal() {
      this.loadingUpload = false;
      this.errorUpload = null;
      this.setDaraTemplateBureauEtudeUploded();
      this.errorUploadRib = null;
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    }
  },
  computed: {
    ...mapGetters([
      'getTemplateBureauEtudeToUpload',
      'getLoadingGetDataTemplateBureauEtude',
      'getErrorTemplateBureauEtudeDataToUpploadeed'
    ])
  },
  components: {
    DatePicker
  }
};
</script>

<style lang="scss" scoped>
.bureau-de-etude-template-block-style {
  display: flex;
  .input-fields-style-for-template {
    width: 39%;
    background-color: #ecedec36;
    margin: 0% 2%;
    padding: 0% 1%;
    border-radius: 16px;
    .fields-input {
      display: inline;
      label {
        font-size: 13px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        text-align: start;
        color: #2a2a2a;
        margin-left: 0%;
        margin-top: 0px;
        margin-bottom: 5px;
      }
    }
  }
  .template-style {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 65px);
    width: 62%;
    padding-right: 6px;
  }
}
</style>
<style lang="scss">
.cutsom-modal-bootstrap .modal-dialog {
  top: 0%;
  max-width: 100%;
  margin: 5px;
}
.template-style::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.template-style::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d0cddc;
  border-radius: 10px;
}
.template-style::-webkit-scrollbar-thumb {
  background: #dadae4;
  border-radius: 7px;
}
.fields-input {
  label {
    font-size: 13px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    text-align: start;
    color: #2a2a2a;
    margin-left: 1%;
    margin-top: 0px;
    margin-bottom: 5px;
  }
  #title-template-description {
    border-radius: 15px;
  }
}
.error-message-rib .error {
  color: #000;
  font-size: 16px;
  padding: 10px 20px;
  background: #f3beb8;
  border: 1px solid #f09898;
}
</style>
